import React, { useState } from "react"
import { useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"
import { clsx } from "clsx"

interface AddTaskButtonProps {
  actionPath: string
  position?: "bottom" | "right" | "left"
  taskId: string
  selected?: boolean
  onHoverOver?: () => void
}

const generatePositionStyles = (position, offset, margin) => {
  if (position === "left") {
    return { left: margin, top: offset, position: "absolute" }
  }

  if (position === "bottom") {
    return { bottom: margin, left: offset, position: "absolute" }
  }

  if (position === "right") {
    return { right: margin, top: offset, position: "absolute" }
  }
}

const PlaceHolderButton = ({ position, taskId, ...props }) => {
  const placeHolderClasses = clsx(
    "pointer-events-auto rounded-full w-4 h-4 bg-purple-500 absolute border border-white",
    {
      "bottom-[-38px] left-[calc(50%-8px)]": position === "bottom",
      "right-[-38px] top-[calc(50%-10px)]": position === "right",
      "left-[-38px] top-[calc(50%-10px)]": position === "left",
    },
  )

  return (
    <div id={`hover-${taskId}-${position}`} className={placeHolderClasses} style={{ zIndex: 2 }} {...props}>
      <span className="sr-only">+</span>
    </div>
  )
}

export const AddTaskButton = ({
  position = "left",
  selected = false,
  actionPath,
  taskId,
  onHoverOver,
}: AddTaskButtonProps) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
    onHoverOver && onHoverOver()
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const offset = "calc(50% - 14px)"
  const margin = "-44px"

  const positionStyles = generatePositionStyles(position, offset, margin)
  const addTaskButtonStyles = {
    height: "28px",
    width: "28px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    borderRadius: 6,
    textAlign: "center",
    pointerEvents: "all",
    zIndex: 2,
    ...positionStyles,
  }
  const {
    selectedTaskId,
    selectedTaskRequestStage,
    taskIsUnsaved,
    redirectToTask,
    workflowSimplificationWorkflowEditing,
  } = useWorkflow()
  const { getViewport } = useReactFlow()

  const handleClick = (e) => {
    e.stopPropagation()

    if (workflowSimplificationWorkflowEditing) {
      redirectToTask(actionPath, JSON.stringify(getViewport()), taskId, selectedTaskId, selectedTaskRequestStage)
    } else {
      const url = new URL(actionPath, window.location.origin)
      url.searchParams.append("viewport_position", JSON.stringify(getViewport()))
      url.searchParams.append("selected", selectedTaskId)
      url.searchParams.append("request_stage", selectedTaskRequestStage)

      location = url.toString()
    }
  }

  const addButtonClasses = clsx("text-white text-xl", { "bg-purple-500": !selected, "bg-purple-700": selected })

  const AddButton = React.memo(() => {
    return (
      <button onClick={handleClick} data-selected={selected} onMouseOut={handleMouseOut}>
        <div id={`add-task-${taskId}-${position}`} style={addTaskButtonStyles} className={addButtonClasses}>
          +
        </div>
      </button>
    )
  }, [])

  if (selected) {
    return <AddButton />
  }

  return isHovering ? (
    <AddButton />
  ) : (
    <PlaceHolderButton taskId={taskId} position={position} onMouseOver={handleMouseOver} data-position={position} />
  )
}
