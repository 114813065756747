import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils/index"

/* Connects to data-controller="canopy--panel-accordion-group-component"
 */
export default class extends Controller {
  static values = {
    dynamicPanelsSelector: String,
  }

  dynamicPanels: HTMLDivElement[]
  dynamicPanelsSelectorValue: string
  panelTriggersMap: Record<HTMLElement, HTMLElement> = {}
  visibleDynamicPanels: Set<HTMLDivElement> = new Set()

  connect = () => {
    this.dynamicPanels = this.element.querySelectorAll(this.dynamicPanelsSelectorValue)

    this.dynamicPanels.forEach((panel) => {
      const openButton = this.panelOpenButton(panel)
      this.panelTriggersMap[openButton] = panel

      openButton.addEventListener("click", this.showPanelOnOpen)
      this.panelCloseButton(panel).addEventListener("click", this.hidePanelOnClose)
    })
  }

  disconnect = () => {
    this.dynamicPanels.forEach((panel) => {
      this.panelOpenButton(panel).removeEventListener("click", this.showPanelOnOpen)
      this.panelCloseButton(panel).removeEventListener("click", this.hidePanelOnClose)
    })
  }

  hidePanelOnClose = (e: MouseEvent) => {
    const button = e.currentTarget as HTMLButtonElement
    const panel = button.closest(this.dynamicPanelsSelectorValue) as HTMLDivElement

    hide(panel)
    this.setOrUnsetAsCurrentAccordionPanel(panel)
    this.visibleDynamicPanels.delete(panel)
  }

  panelCloseButton = (panel: HTMLDivElement) => {
    return panel.querySelector(panel.dataset.closeButtonSelector)
  }

  panelOpenButton = (panel: HTMLDivElement) => {
    return this.element.querySelector(panel.dataset.openButtonSelector)
  }

  setOrUnsetAsCurrentAccordionPanel = (panel: HTMLDivElement) => {
    panel.querySelector("details > summary").click()
  }

  showPanelOnOpen = (e: MouseEvent) => {
    const button = e.currentTarget as HTMLButtonElement
    const panel = this.panelTriggersMap[button]
    if (this.visibleDynamicPanels.has(panel)) return

    show(panel)
    this.setOrUnsetAsCurrentAccordionPanel(panel)
    this.visibleDynamicPanels.add(panel)
  }
}
