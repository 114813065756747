import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-component"
export default class extends Controller {
  static targets = ["tableComponent", "tableInner"]
  tableComponentTarget: HTMLDivElement
  tableInnerTarget: HTMLDivElement

  static values = {
    stickyBulkActions: Boolean,
    // Makes columns sticky to the left or right side
    stickyColumns: Boolean,
    // If present, determines how many columns to stick on the left
    stickyColumnIndex: String,
    // If present, determines how many columns to stick on the right
    reverseStickyColumnIndex: String,
    stickyTable: Boolean,
    headerRowBgColors: Array,
  }

  hasTableInnerTarget: boolean
  stickyBulkActionsValue: boolean
  stickyColumnsValue: boolean
  stickyColumnIndexValue: string
  reverseStickyColumnIndexValue: string
  stickyTableValue: boolean
  headerRowBgColorsValue: string[]

  connect() {
    if (this.stickyColumnsValue) {
      this.makeColumnsSticky()
    }

    if (this.stickyTableValue) {
      this.makeHeaderSticky()
    }

    if (this.stickyBulkActionsValue && this.hasTableInnerTarget) {
      this.adjustScrollableAreas()
    }
  }

  adjustScrollableAreas() {
    const bulkEditHeader = this.element.querySelector(".bulk-selection-header")

    if (!bulkEditHeader) {
      return
    }

    const clone = bulkEditHeader.cloneNode(true)
    const stickyTableClassOffset = 264

    // Clone element outside of view to get hidden bulk header element height
    clone.setAttribute("style", "display:block;visibility:hidden;position:fixed;left:100%")
    bulkEditHeader.after(clone)
    this.tableInnerTarget.setAttribute(
      "style",
      `max-height: calc(100vh - ${stickyTableClassOffset + clone.offsetHeight}px)`,
    )
    this.tableInnerTarget.classList.add("overflow-auto", "w-full")
    this.element.classList.add("overflow-hidden")
    clone.remove()
  }

  makeHeaderSticky() {
    const headerRow = this.tableComponentTarget.getElementsByClassName("table-header-group")[0]
    const headerRowCells = headerRow.getElementsByClassName("table-cell")

    Array.prototype.forEach.call(headerRowCells, (cell, index) => {
      if (
        (this.hasStickyColumnIndex() && index <= this.getStickyColumnIndex()) ||
        (this.hasReverseStickyColumnIndex() && index >= this.getReverseStickyColumnIndex())
      ) {
        cell.style.zIndex = 10
      } else {
        cell.style.zIndex = 5
      }

      cell.style.top = "0px"
      cell.style.position = "sticky"
      cell.classList.add(
        ...["bg-inherit", "border-t", "border-b", "shadow-[2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"],
      )
    })
  }

  makeColumnsSticky() {
    const headerRow = this.tableComponentTarget.getElementsByClassName("table-header-group")[0]
    if (headerRow) {
      this.makeCellsSticky(headerRow)
    }

    const tableRows = this.tableComponentTarget.getElementsByClassName("table-row")
    Array.prototype.forEach.call(tableRows, (row) => this.makeCellsSticky(row))
  }

  hasStickyColumnIndex() {
    return !isNaN(parseInt(this.stickyColumnIndexValue))
  }

  getStickyColumnIndex() {
    return parseInt(this.stickyColumnIndexValue)
  }

  hasReverseStickyColumnIndex() {
    return !isNaN(parseInt(this.reverseStickyColumnIndexValue))
  }

  getReverseStickyColumnIndex() {
    return parseInt(this.reverseStickyColumnIndexValue)
  }

  makeCellsSticky = (row) => {
    const rowCells = row.getElementsByClassName("table-cell")
    let offsetLeft = 0
    let offsetRight = 0
    let headerRow = false
    this.headerRowBgColorsValue.forEach((bgColor) => {
      if (row.classList.contains(bgColor)) {
        headerRow = true
      }
    })

    Array.prototype.forEach.call(rowCells, (cell, index) => {
      if (this.hasStickyColumnIndex() && index <= this.getStickyColumnIndex()) {
        cell.style.left = `${offsetLeft - 1}px`
        cell.style.position = "sticky"
        cell.style.zIndex = 5

        if (headerRow) {
          cell.classList.add("bg-inherit")
        } else {
          cell.classList.add(...["bg-white", "group-hover:bg-inherit"])
        }

        if (index == this.getStickyColumnIndex()) {
          cell.classList.add(...["border-r", "shadow-[2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"])
        }

        offsetLeft += cell.offsetWidth - 1
      }

      if (this.hasReverseStickyColumnIndex() && index >= rowCells.length - (this.getReverseStickyColumnIndex() + 1)) {
        cell.style.right = `${offsetRight}px`
        cell.style.position = "sticky"
        cell.style.zIndex = 5

        if (headerRow) {
          cell.classList.add("bg-inherit")
        } else {
          cell.classList.add(...["bg-white", "group-hover:bg-inherit"])
        }

        if (index == rowCells.length - (this.getReverseStickyColumnIndex() + 1)) {
          cell.classList.add(...["border-l", "shadow-[-2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"])
        }

        offsetRight += cell.offsetWidth
      }
    })
  }
}
