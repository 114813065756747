import React from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"

interface RequestStageIconProps {
  stage: string
}

export const RequestStageIcon = ({ stage }: RequestStageIconProps) => {
  const { workflowSimplificationWorkflowEditing } = useWorkflow()

  const renderIcon = () => {
    if (stage === "Requirements gathering") {
      return <i className="fa fa-rectangle-list text-blue-500"></i>
    } else if (stage === "Negotiation") {
      return <i className="fa fa-messages-dollar text-green-600"></i>
    } else if (stage === "Approvals and Signatures") {
      return <i className="fa fa-signature text-gold-600"></i>
    } else if (stage === "Closing") {
      return <i className="fa fa-flag-checkered text-pink-600"></i>
    } else if (stage === "Savings review") {
      return <i className="fa fa-piggy-bank text-purple-600"></i>
    }
  }

  if (!workflowSimplificationWorkflowEditing) {
    return null
  }

  return <span className="text-sm mr-1 h-[15px] w-[15px] flex justify-center items-center">{renderIcon()}</span>
}
