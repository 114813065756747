import React from "react"
import { Handle, Position, useNodes } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { AddFirstTaskButton } from "./addFirstTaskButton"
import { AssignPurchaseCategoryCardNode } from "./assignPurchaseCategoryCardNode"
import { EditIntakeFormCardNode } from "./editIntakeFormCardNode"

const noTasks = (nodes) => nodes.filter((node) => node.type === "workflowTaskCard").length === 0

// Remove this component when the workflow_simplification_workflow_editing flag is removed
export const LegacyWorkflowRootTaskCardNode = ({ data }) => {
  const nodes = useNodes()

  const { cardDimensions, editable } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="pointer-events-auto z-2 p-4 border border-base rounded-md bg-gray-50"
      style={containerStyles}
    >
      <>
        <div className="flex flex-col space-y-2">
          <div className="space-y-1">
            <>
              <div className="flex flex-row">
                <div className="truncate small-heading">{data.cardHeader}</div>
              </div>
              <div className="flex-row pt-2">
                <div className="text-sm font-normal text-gray-600">{data.cardBody}</div>
              </div>
              <div className="flex flex-row items-center pt-6 text-purple-500">
                <i className="fa-solid fa-book fa-sm"></i>
                <a className="pl-1 link-text" href={data.workflowGuideUrl} target="_blank">
                  {data.workflowGuideText}
                </a>
              </div>
            </>
          </div>
        </div>
        <Handle type="source" position={Position.Right} style={handleStyles} />
        {editable && data.addTaskPathRight && noTasks(nodes) && (
          <AddFirstTaskButton actionPath={data.addTaskPathRight} />
        )}
      </>
    </div>
  )
}

export const workflowRootTaskCardNode = (props) => {
  const { workflowSimplificationWorkflowEditing } = useWorkflow()

  // Conditionally displays the old vs new components based on the
  // workflow_simplification_workflow_editing feature flag
  if (!workflowSimplificationWorkflowEditing) {
    return <LegacyWorkflowRootTaskCardNode {...props} />
  }

  const { data } = props

  return !data?.requestCategory ? <AssignPurchaseCategoryCardNode {...props} /> : <EditIntakeFormCardNode {...props} />
}

export default workflowRootTaskCardNode
