import { Controller } from "@hotwired/stimulus"

/* Connects to data-controller="canopy--panel-accordion-component"
 */
export default class extends Controller {
  connect = () => {
    this.adjustContentHeightToFillPanel()
  }

  adjustContentHeightToFillPanel = () => {
    const summary = this.element.querySelector("summary") as HTMLElement | undefined
    const content = this.element.querySelector(".panel_accordion_content") as HTMLDivElement

    const summaryHeight = summary?.offsetHeight ?? 0
    content.style.height = `calc(100% - ${summaryHeight}px)`
  }
}
