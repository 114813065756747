import React from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"

interface AddTaskButtonProps {
  actionPath: string
  subworkflow: boolean
}

// Remove this component when the workflow_simplification_workflow_editing flag is removed
const LegacyAddFirstTaskButton = ({ actionPath, subworkflow }: AddTaskButtonProps) => {
  const { getViewport } = useReactFlow()
  const { workflowSimplificationWorkflowEditing } = useWorkflow()
  const handleClick = (e) => {
    e.stopPropagation()
    const url = `${actionPath}&viewport_position=${JSON.stringify(getViewport())}`
    location = url
  }

  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "103px",
    height: "1px",
    background: "#6A778A",
    border: "none",
    borderRadius: "0px",
    transform: "none",
    right: "-90px",
    zIndex: -1,
  }

  const content = subworkflow === true ? "Subworkflow is initiated" : "Request is submitted"

  return (
    <>
      <Handle type="source" position={Position.Right} style={handleStyles} />
      <button onClick={handleClick}>
        <div
          id={`add-first-task`}
          className="absolute pointer-events-auto z-10 bg-gray-50 right-[-360px] top-[calc(50%-49px)] border border-base rounded-md py-4 px-10 text-center shadow space-y-1"
        >
          <div className="font-semibold text-base text-purple-500 flex items-center justify-center">
            <i className="fas fa-square-plus px-1"></i>
            <div>Add a Task</div>
          </div>
          <div className="text-base w-48 text-gray-600">Create a Task to trigger after a {content}</div>
        </div>
      </button>
    </>
  )
}

// Rename this to "AddFirstTaskButton" and export the constant (export const)
// when the workflow_simplification_workflow_editing flag is removed
const NewAddFirstTaskButton = ({ actionPath, subworkflow }: AddTaskButtonProps) => {
  const { getViewport } = useReactFlow()
  const handleClick = (e) => {
    e.stopPropagation()
    const url = `${actionPath}&viewport_position=${JSON.stringify(getViewport())}`
    location = url
  }

  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "103px",
    height: "1px",
    background: "#6A778A",
    border: "none",
    borderRadius: "0px",
    transform: "none",
    right: "-90px",
    zIndex: -1,
  }

  return (
    <>
      <Handle type="source" position={Position.Right} style={handleStyles} />
      <button onClick={handleClick}>
        <div
          id={`add-first-task`}
          className="absolute pointer-events-auto z-10 rounded-md text-center shadow space-y-1 bg-purple-500 py-2 px-3 right-[-205px] top-[calc(50%-18px)]"
        >
          <div className="font-semibold text-base text-white flex items-center justify-center">
            <i className="fas fa-plus px-1"></i>
            <div>Add a Task</div>
          </div>
        </div>
      </button>
    </>
  )
}

// Conditionally displays the old vs new component based on the feature flag
// Remove this component when the workflow_simplification_workflow_editing flag is removed
export const AddFirstTaskButton = (props: AddTaskButtonProps) => {
  const { workflowSimplificationWorkflowEditing } = useWorkflow()

  return workflowSimplificationWorkflowEditing ? (
    <NewAddFirstTaskButton {...props} />
  ) : (
    <LegacyAddFirstTaskButton {...props} />
  )
}
