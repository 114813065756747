import { Controller } from "@hotwired/stimulus"
import { disable } from "../utils"

// Connects to data-controller="dept-template-spend-approval-group"
export default class extends Controller {
  static targets = ["editLevelButton"]
  editLevelButtonTargets: HTMLDivElement[]

  disableEditButtons() {
    this.editLevelButtonTargets.forEach((button) => {
      const parent = button.parentElement
      parent.classList.add("pointer-events-none")
      disable(button)
    })
  }
}
