import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"
import { trigger } from "../utils/events"
import { TomInput } from "tom-select/src/types/core"
import { get } from "@rails/request.js"
import { buildUrl } from "../utils/urls"

// Connects to data-controller="nonlinear-task-form"
export default class extends Controller {
  // values
  static values = {
    changeQuestionPath: String,
    conditions: Array<String>,
    groupLabelNames: Array<String>,
  }
  changeQuestionPathValue: string
  groupLabelNamesValue: string[]

  // targets
  static targets = [
    "budgetCentsRuleField",
    "ruleEndValue",
    "ruleEndValueAnd",
    "rulesToggleConditionally",
    "comparator",
    "groupLabelCaption",
    "groupLabelName",
    "requestFormQuestionRuleField",
    "rulesForm",
    "ruleField",
    "subworkflowToApplyContainer",
    "subworkflowToApplyField",
  ]
  budgetCentsRuleFieldTarget: HTMLDivElement
  ruleEndValueTarget: HTMLInputElement
  ruleEndValueAndTarget: HTMLInputElement
  rulesToggleConditionallyTarget: HTMLInputElement
  comparatorTarget: HTMLInputElement
  groupLabelCaptionTarget: HTMLElement
  groupLabelNameTarget: TomInput
  requestFormQuestionRuleFieldTarget: HTMLInputElement
  ruleFieldTarget: HTMLInputElement
  rulesFormTarget: HTMLFormElement
  subworkflowToApplyContainerTarget: HTMLDivElement
  subworkflowToApplyFieldTarget: HTMLSelectElement

  connect() {
    this.toggleRulesEnabled()
    this.renderRulesFields()
    this.onRuleFieldChange()

    if (this.hasRulesFormTarget) {
      show(this.selectedRuleField(this.ruleFieldTarget.value))
      if (this.ruleFieldTarget.value !== "request_form_question") {
        this.makeRequestFormQuestionRuleFieldsNotRequired()
      }
    }
    document.addEventListener("drawer:close", () => this.triggerClosedEvent())
  }

  disconnect() {
    document.removeEventListener("drawer:close", () => this.triggerClosedEvent())
  }

  triggerClosedEvent() {
    trigger("editForm:close", {})
  }

  onGroupLabelChange() {
    const selectedLabel = this.groupLabelNameTarget?.tomselect?.getValue() as string
    if (selectedLabel) {
      if (!this.groupLabelNamesValue.includes(selectedLabel)) {
        show(this.groupLabelCaptionTarget)
      } else {
        hide(this.groupLabelCaptionTarget)
      }
    } else {
      hide(this.groupLabelCaptionTarget)
    }
  }

  onSubworkflowSelectionChange(e: Event): void {
    if ((e.target as HTMLInputElement).value == "default_for_requests") {
      show(this.subworkflowToApplyContainerTarget)
    } else {
      hide(this.subworkflowToApplyContainerTarget)
      this.subworkflowToApplyFieldTarget.value = ""
    }
  }

  onRuleQuestionChange(e: Event): void {
    const queryParams = {
      form_field_id: event.target.value,
    }

    const url = buildUrl(this.changeQuestionPathValue, queryParams)
    get(url, { responseKind: "turbo-stream" })
  }

  toggleRulesEnabled(): void {
    if (!this.hasRulesFormTarget) return

    if (this.rulesEnabled()) {
      show(this.rulesFormTarget)
      this.toggleRuleFields(true)
      this.onRuleFieldChange()
    } else {
      hide(this.rulesFormTarget)
      this.toggleRuleFields(false)
      this.makeRequestFormQuestionRuleFieldsNotRequired()
    }
  }

  onComparatorChange(): void {
    this.renderRulesFields()
  }

  onRuleFieldChange(): void {
    if (!this.rulesEnabled()) return

    const selectedRuleField = this.selectedRuleField(this.ruleFieldTarget.value)
    const ruleFields = Object.keys(this.ruleFieldTargetMap())

    ruleFields.forEach((ruleField) => {
      if (ruleField != this.ruleFieldTarget.value) {
        const field = this.selectedRuleField(ruleField)
        hide(field)
        this.toggleInputFields(false, field)
        if (this.ruleFieldTarget.value == "request_form_question") {
          this.makeRequestFormQuestionRuleFieldsNotRequired()
        }
      } else {
        show(selectedRuleField)
        this.toggleInputFields(true, selectedRuleField)
        if (this.ruleFieldTarget.value == "request_form_question") {
          this.makeRequestFormQuestionRuleFieldsRequired()
        }
      }
    })
  }

  private selectedRuleField(ruleField): HTMLDivElement {
    return this.ruleFieldTargetMap()[ruleField]
  }

  private ruleFieldTargetMap() {
    return {
      budget_cents: this.budgetCentsRuleFieldTarget,
      request_form_question: this.requestFormQuestionRuleFieldTarget,
    }
  }

  private toggleRuleFields(enabled: Boolean): void {
    const container = document.querySelector("#rulesForm")
    if (!container) return
    this.toggleInputFields(enabled, container)
  }

  private toggleInputFields(enabled: Boolean, container: any): void {
    const inputs = container?.querySelectorAll("input") || []

    inputs.forEach((input: HTMLInputElement) => {
      input.disabled = !enabled
    })
  }

  private renderRulesFields(): void {
    if (!this.hasComparatorTarget) return

    const comparator = this.comparatorTarget.value
    if (comparator === "between") {
      show(this.ruleEndValueTarget)
      show(this.ruleEndValueAndTarget)
    } else {
      hide(this.ruleEndValueTarget)
      hide(this.ruleEndValueAndTarget)
    }
  }

  private rulesEnabled(): boolean {
    return this.hasRulesToggleConditionallyTarget && this.rulesToggleConditionallyTarget.checked
  }

  private makeRequestFormQuestionRuleFieldsRequired(): void {
    let ruleAnswerSelect = this.ruleAnswerSelectElement()
    let ruleQuestionSelect = this.ruleQuestionSelectElement()

    if (ruleAnswerSelect) {
      ruleAnswerSelect.setAttribute("required", "")
    }
    if (ruleQuestionSelect) {
      ruleQuestionSelect.setAttribute("required", "")
    }
  }

  private makeRequestFormQuestionRuleFieldsNotRequired(): void {
    let ruleAnswerSelect = this.ruleAnswerSelectElement()
    let ruleQuestionSelect = this.ruleQuestionSelectElement()

    if (ruleAnswerSelect) {
      ruleAnswerSelect.removeAttribute("required")
    }
    if (ruleQuestionSelect) {
      ruleQuestionSelect.removeAttribute("required")
    }
  }

  private ruleQuestionSelectElement(): HTMLSelectElement | null {
    if (!this.hasRulesFormTarget) {
      return
    }
    return this.rulesFormTarget.querySelector("#question_id")
  }

  private ruleAnswerSelectElement(): HTMLSelectElement | null {
    if (!this.hasRulesFormTarget) {
      return
    }
    return this.rulesFormTarget.querySelector("#answer_key")
  }
}
